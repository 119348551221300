<template>
  <div class="photo-controls d-flex justify-space-between">
    <div
      v-if="showArrows"
      class="photo-prev d-flex align-center justify-center px-2"
      :class="{ hoverable: isLeftActive }"
      @click="goLeft"
    >
      <v-icon :color="isLeftActive && '#ed3730'">mdi-chevron-left</v-icon>
    </div>
    <div
      v-for="photo in filteredPhotos"
      :key="photo.url"
      class="photo-item d-flex"
      @click="() => $emit('photoClicked', photo.url)"
    >
      <img :src="photo.url" :alt="photo.name" />
    </div>
    <div
      v-if="showArrows"
      class="photo-next d-flex align-center justify-center px-2"
      :class="{ hoverable: isRightActive }"
      @click="goRight"
    >
      <v-icon :color="isRightActive && '#ed3730'">mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhotoSwitcher",
  props: {
    photos: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      start: 0
    };
  },
  computed: {
    VISIBLE_THUMBNAILS() {
      return window.innerWidth < 500 ? 2 : 4;
    },
    isLeftActive() {
      return this.start > 0;
    },
    isRightActive() {
      return this.start < this.photos.length - this.VISIBLE_THUMBNAILS;
    },
    filteredPhotos() {
      return this.photos.slice(
        this.start,
        Math.min(this.photos.length, this.start + this.VISIBLE_THUMBNAILS)
      );
    },
    showArrows() {
      return this.photos.length > this.VISIBLE_THUMBNAILS;
    }
  },
  methods: {
    goLeft() {
      this.start = Math.max(this.start - 1, 0);
    },
    goRight() {
      const maxValue = this.photos.length - this.VISIBLE_THUMBNAILS;
      this.start = Math.min(this.start + 1, maxValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.photo-item {
  height: 100px;
  width: 100%;
  margin: 0 10px;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.hoverable:hover {
  cursor: pointer;
}
</style>
