<template>
  <li>
    <div
      v-if="inManstock || inLocation"
      class="d-flex flex-column d-sm-block in-location"
    >
      <div class="in-stock text-center text-sm-left">
        In stock
        <span class="hidden-sm-and-up">- {{ days }}</span>
        <div class="icon">
          <font-awesome-icon
            icon="shopping-cart"
            :style="{ color: 'white' }"
          ></font-awesome-icon>
        </div>
      </div>
      <div class="delivery hidden-xs-only">
        {{ days }}
        <div class="icon">
          <font-awesome-icon
            icon="truck"
            :style="{ color: 'white' }"
          ></font-awesome-icon>
        </div>
      </div>
    </div>
    <div v-else class="out-of-stock">Out of stock</div>
  </li>
</template>

<script>
import cfg from "./../../3c.config";

export default {
  name: "AvailabilityIconAeroflow",
  props: {
    item: Object
  },
  computed: {
    inManstock() {
      const candidate = this.item.available.find(
        location => location.location === "manufacturer"
      );
      if (candidate) {
        return candidate.quantityavailable;
      } else {
        return 0;
      }
    },
    inLocation() {
      if (this.item && this.item.available) {
        const avlInLocation = this.item.available.find(
          item => item.location === cfg.LOCATION_EU
        );
        return (avlInLocation && +avlInLocation.quantityavailable) || 0;
      }
      return 0;
    },
    days() {
      if (this.inLocation) {
        return "2-4 days delivery";
      } else if (this.inManstock) {
        return "2-3 weeks delivery";
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.in-location {
  .in-stock,
  .delivery {
    color: white;
    background-color: #5fbc46;

    @media screen and (min-width: 600px) {
      background-color: white;
      color: #5fbc46;
      border: 1px solid #5fbc46;
    }

    .icon {
      background-color: #5fbc46;
    }
  }

  .delivery {
    color: white;
    background-color: #5fbc46 !important;
  }
}
.in-transit {
  .in-stock,
  .delivery {
    color: white;
    background-color: orange;

    @media screen and (min-width: 600px) {
      background-color: white;
      color: orange;
      border: 1px solid orange;
    }

    .icon {
      background-color: orange;
    }
  }

  .delivery {
    color: white;
    background-color: orange;
  }
}

.in-stock,
.delivery {
  text-transform: uppercase;
  padding: 10px;
  display: inline-block;

  @media screen and (min-width: 600px) {
    position: relative;
    margin-left: 20px;
    padding: 10px 10px 10px 30px;
  }

  .icon {
    display: none;
    left: -20px;
    height: 110%;
    top: -2px;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 45px;
    padding: 5px;
    border-radius: 50%;

    @media screen and (min-width: 600px) {
      display: flex;
    }
  }
}

.in-stock {
  padding-right: 25px;
}

.delivery {
  margin-top: 10px;
  padding-left: 10px;

  @media screen and (min-width: 600px) {
    padding-left: 20px;
    border-radius: 0 25px 25px 0;
    margin-left: 10px;
    margin-top: 0;
  }

  .icon {
    height: 105%;
    top: -1px;
    left: -28px;
  }
}

.out-of-stock {
  padding: 10px;
  text-transform: uppercase;
  color: white;
  background-color: gray;
  display: inline-block;
}
</style>
