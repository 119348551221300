<template>
  <div class="section-company">
    <b-form-checkbox
      id="is-company"
      ref="isCompany"
      v-validate
      class="mb-4"
      name="isCompany"
      :checked="value"
      @input="handleChange"
    >
      {{ $t("BASKET.NO_VAT") }}
    </b-form-checkbox>
    <b-form-group v-show="value" :invalid-feedback="veeErrors.first('vat')">
      <b-form-input
        id="vat"
        v-validate="`required_if:isCompany,true|vat:${cartId}`"
        data-vv-delay="500"
        name="vat"
        v-model="vat"
        type="text"
        :placeholder="$t('BASKET.VAT_NUMBER')"
        :state="validateState('vat')"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "TheCompany",
  props: {
    cartId: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vat: ""
    };
  },
  methods: {
    handleChange(value) {
      this.$emit("input", !!value);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped></style>
