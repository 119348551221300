import axios3C from "@/axios";

const validateVat = async (value, args) => {
  try {
    const res = await axios3C.post("/cart", {
      action: "updateCart",
      field: "taxnumber",
      cart_id: args.cart_id,
      value,
      customer: "guest"
    });
    return res.data.shoppingcarts[0].shoppingcart.validtaxnumber;
  } catch (e) {
    return false;
  }
};

export const vatValidator = {
  getMessage() {
    return "Invalid VAT number";
  },
  validate: validateVat
};
