<template>
  <div>
    <div class="header-wrapper-exception xtreme-banner">
      <h2>Xtreme Clutch</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Xtreme Clutch jest od dziesięcioleci w czołówce technologii
            sprzęgieł wysokiej wydajności. Własny sprzęt testujący i bliskie
            związki ze sportem motorowym umożliwiły Xtreme Clutch dokładne
            testowanie różnych kombinacji materiałów i konstrukcji tarcz w celu
            dostosowania ich do pracy przy dużych obciążeniach i w
            zastosowaniach wyścigowych.
          </p>
          <p>
            Nazwa Xtreme Clutch stała się synonimem wysokiej wydajności. Xtreme
            zyskał reputację jako największy australijski producent i
            dystrybutor zestawów sprzęgieł i ich komponentów. Twoje sprzęgło
            Xtreme zostało profesjonalnie zaprojektowane i opracowane jako
            zupełnie nowy produkt, czego efektem jest sprzęgło, które przewyższa
            wymagania dotyczące osiągów, zużycia i właściwości jezdnych.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/p10_xtreme.webp"
            />
            <img
              src="./../../assets/brands-logos/p10_xtreme.png"
              alt="Xtreme Clutch"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="#fafafa"
    ></page-header>

    <b-container class="my-container">
      <div class="link-items">
        <router-link to="/catalogue/xclutch">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/file/d/1S4aSZ6K1Cg-10GzhILlXAYz0rMMhLSab/view"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://xtremeclutch.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/open?id=1NT1VL94gTTVv86gyEVXw_Az_eDEnPw1b'
              : 'https://drive.google.com/open?id=1759GE7hGRoH82PPUrqVgt987k1zHqnuz'
          "
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("vehicleInformationSheets") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1605Ck2GoRlUUfdtf-v3U1b2HuZOlLaTK'
              : 'https://drive.google.com/open?id=1459hK2QQsNYz7EugGVLkK2dqxo8D7vHg'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCRfZxyw0nqAwcmbZmPlkEnA"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/vgSeryxUh84"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>CLUTCH TECH: ACS Pressure Sprayer</p>
          <p>The Australian Clutch Services pressure sprayer is a high quality, German made, 1 litre spray bottle which has been designed to be used with a wide range of solvents for cleaning bell housings, flywheels, pressure plates, brake rotors etc. For more information and to find your closest distributor, please visit www.australianclutch.com.au</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "BrandXClutchEn",
  components: { Brands, PageHeader },
  computed: {
    ...mapGetters(["isUserLogged"])
  }
};
</script>
