<template>
  <div class="icon">
    <img
      v-for="icon in iconsToDisplay"
      class="icon-item"
      :key="icon.key"
      :src="icon.img"
      :alt="icon.key"
      @click="() => updateConfig(icon.key)"
    />
  </div>
</template>

<script>
import i18n from "../../../../i18n";
import { mapMutations, mapState } from "vuex";
import axios3C from "@/axios";

import pl from "../../../../assets/pl512.png";
import en from "../../../../assets/uk512.png";
import de from "../../../../assets/germany.png";

const icons = [
  {
    key: "pl",
    img: pl
  },
  {
    key: "en",
    img: en
  },
  {
    key: "de",
    img: de
  }
];

export default {
  name: "Locale",
  data: function() {
    return {
      i18n
    };
  },
  computed: {
    ...mapState(["cart_id", "lang"]),
    iconsToDisplay() {
      return icons.filter(i => i.key !== this.lang);
    }
  },
  methods: {
    ...mapMutations(["setLang"]),
    updateConfig: async function(country) {
      if (this.cart_id) {
        await axios3C.post("/cart", {
          action: "updateCart",
          cart_id: this.cart_id,
          field: "currency",
          value: country === pl ? "pln" : "eur",
          customer: "guest"
        });
      }
      this.setLang(country);
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  z-index: 200;
  margin-left: 15px;
  width: 80px;
}

.icon-item {
  margin-right: 5px;
  width: 30px;
  z-index: 200;

  &:hover {
    cursor: pointer;
  }
}
</style>
