<template>
  <div class="side-info-wrapper">
    <div class="item subtotal">
      <div class="header mb-6">Basket Summary</div>
      <div class="subtotal-row">
        <div>Subtotal: ({{ getBasketCount }} items)</div>
        <div>
          {{ (summary.itemsamount - airfreightCost).toFixed(2) }} {{ symbol }}
        </div>
      </div>
      <small>
        {{
          $t("BASKET.SHIPPING_NOT_INCLUDED", {
            optional: includesService
              ? null
              : $t("BASKET.SHIPPING_NOT_INCLUDED_OPTIONAL")
          })
        }}
      </small>
    </div>
    <div class="item tax">
      <div v-if="airfreightCost" class="opposite">
        <div>Airfreight Services</div>
        <div>{{ airfreightCost.toFixed(2) }} {{ symbol }}</div>
      </div>
      <!-- <div class="item-header">{{ $t("BASKET.TAX_AND_SHIPPING") }}</div> -->
      <div class="opposite total-delivery">
        <div>
          <i18n path="BASKET.TOTAL_SHIPPING_COST" tag="span">
            <template v-if="!includesService" #total>
              {{ $t("BASKET.TOTAL") }}
            </template>
          </i18n>
          <v-tooltip
            v-if="summary.shippingcost === 0 || !summary.shippingmethod"
            bottom
          >
            <template #activator="{ on }">
              <v-icon color="primary" dark v-on="on"
                >mdi-help-circle-outline</v-icon
              >
            </template>
            <span>{{ $t("BASKET.SHIPPING_TOOLTIP") }}</span>
          </v-tooltip>
        </div>
        <div>{{ summary.shippingcost.toFixed(2) }} {{ symbol }}</div>
      </div>
      <div class="opposite">
        <div>{{ $t("BASKET.TAX") }}</div>
        <div>{{ summary.taxamount.toFixed(2) }} {{ symbol }}</div>
      </div>
    </div>
    <div class="item">
      <div class="opposite total">
        <div>{{ $t("BASKET.IN_TOTAL") }}</div>
        <div>{{ summary.grossamount.toFixed(2) }} {{ symbol }}</div>
      </div>
    </div>
    <div v-if="summary.backorderquantity" class="item backorder-info">
      *{{ $t("BASKET.BACKORDER") }}
    </div>
    <div class="actions">
      <v-text-field
        v-show="currentPromo"
        :value="currentPromo"
        :label="$t('BASKET.CURRENT_PROMO')"
        disabled
        outlined
        dense
      ></v-text-field>
      <v-text-field
        v-show="!currentPromo"
        v-model="newPromo"
        :disabled="loading"
        :label="$t('BASKET.ENTER_PROMO')"
        outlined
        dense
      ></v-text-field>
      <v-btn
        block
        depressed
        :disabled="(!currentPromo && !newPromo.length) || loading"
        :loading="loading"
        @click="handlePromo"
        >{{ buttonText }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideInfo",
  props: {
    includesService: Boolean,
    cart: {
      type: Object,
      required: true
    },
    summary: {
      type: Object,
      required: true
    },
    symbol: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      newPromo: ""
    };
  },
  computed: {
    ...mapGetters(["getBasketCount"]),
    buttonText() {
      return !this.currentPromo
        ? this.$t("BASKET.APPLY_CODE")
        : this.$t("BASKET.CHANGE_CODE");
    },
    currentPromo() {
      return this.summary.promocode;
    },
    airfreightCost() {
      let cost = 0;
      this.cart.shoppingcart.transactionlines?.forEach(line => {
        if (line.item.recordtype === "service") {
          cost += line.amount;
        }
      });
      return cost;
    }
  },
  methods: {
    handlePromo() {
      this.$emit("promoApplied", !this.currentPromo ? this.newPromo : "");
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  font-size: 18px;
  font-weight: 600;
}
.side-info-wrapper {
  border: 1px solid #ced4da;
  word-spacing: 2px;
  position: sticky;
  top: 20px;

  .opposite {
    display: flex;
    justify-content: space-between;
  }

  .item-header {
    margin-bottom: 10px;
  }

  .item {
    padding: 20px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ced4da;
    }
  }

  .total-delivery {
    margin-top: 10px;
  }

  .subtotal {
    .subtotal-row {
      display: flex;
      justify-content: space-between;
    }
  }

  .actions {
    padding: 20px;
  }

  .total {
    font-size: 1.1em;
    font-weight: bolder;
  }

  .actions {
    padding: 20px;
  }
}

.backorder-info {
  font-size: 13px;
}
</style>
