<template>
  <div>
    <div class="header-wrapper-exception xtreme-banner">
      <h2>Xtreme Outback</h2>
      <hr class="line" />
    </div>

    <b-container>
      <div class="grid">
        <div class="text">
          <p>
            Xtreme Outback jest liderem rynku w dziedzinie rozwoju sprzęgieł
            Heavy Duty i High Performance w Australii.
          </p>
          <p>
            Szeroko zakrojone badania i prace rozwojowe pozwalają Xtreme Outback
            stale analizować nowe materiały cierne i oceniać sprężystość w celu
            uzyskania optymalnej kombinacji osiągów, tłumienia hałasu i
            trwałości. Xtreme Outback jest zaufanym liderem w branży. Xtreme
            Outback jest działem Australian Clutch Services, australijskich
            specjalistów ds. sprzęgieł i kół zamachowych.
          </p>
        </div>
        <div class="image">
          <picture>
            <source
              type="image/webp"
              srcset="./../../assets/brands-logos/xtreme.webp"
            />
            <img
              src="./../../assets/brands-logos/xtreme.png"
              alt="Xtreme Outback"
            />
          </picture>
        </div>
      </div>
    </b-container>

    <page-header
      :text="$t('importantLinks')"
      font="black"
      back="white"
    ></page-header>

    <b-container>
      <div class="link-items">
        <router-link to="/catalogue/xoutback">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} ON-LINE
          </div>
        </router-link>
        <a
          href="https://drive.google.com/open?id=1QyqrkuYBWCkpBkbQm6235OFlxdvSWK9_"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("catalogue") }} PDF
          </div>
        </a>
        <a href="https://www.xtremeoutback.eu/" target="_blank">
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("website") }}
          </div>
        </a>
        <a
          :href="
            $source === 'ozpartseu'
              ? 'https://drive.google.com/drive/folders/1QyqrkuYBWCkpBkbQm6235OFlxdvSWK9'
              : 'https://drive.google.com/open?id=1I0dejSBPKO-wT8MwL9O73-9tXk_qn3vb'
          "
          target="_blank"
          v-if="isUserLogged"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("usefulFiles") }}
            <ul class="link-items-list">
              <li>{{ $t("usefulFiles1") }}</li>
              <li>{{ $t("usefulFiles2") }}</li>
              <li>{{ $t("usefulFiles3") }}</li>
              <li>{{ $t("usefulFiles4") }}</li>
              <li>{{ $t("usefulFiles5") }}</li>
            </ul>
          </div>
        </a>
        <a
          href="https://www.youtube.com/channel/UCRfZxyw0nqAwcmbZmPlkEnA"
          target="_blank"
        >
          <div class="link-item">
            <v-icon left class="icon">mdi-play</v-icon>
            {{ $t("youtube") }}
          </div>
        </a>
      </div>
      <!-- <div class="youtube-wrapper">
        <div class="youtube">
          <iframe
            width="100%"
            height="360"
            src="https://www.youtube.com/embed/vgSeryxUh84"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <p>CLUTCH TECH: ACS Pressure Sprayer</p>
          <p>The Australian Clutch Services pressure sprayer is a high quality, German made, 1 litre spray bottle which has been designed to be used with a wide range of solvents for cleaning bell housings, flywheels, pressure plates, brake rotors etc. For more information and to find your closest distributor, please visit www.australianclutch.com.au</p>
        </div>
      </div>-->
      <brands class="brands" />
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Brands from "@/components/brands/Brands.vue";
import PageHeader from "@/components/common/PageHeader.vue";

export default {
  name: "XOutbackPl",
  computed: {
    ...mapGetters(["isUserLogged"])
  },
  components: { Brands, PageHeader }
};
</script>
