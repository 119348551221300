<template>
  <div>
    <v-overlay :value="showDialog" z-index="200"></v-overlay>
    <v-footer fixed class="content" v-if="showDialog">
      <v-container>
        <div
          v-if="lang === 'en' || lang === 'pl'"
          class="d-flex flex-column flex-md-row justify-center align-center"
        >
          <p>
            This site uses cookies and similar technologies to help us to make
            this site work properly and to provide you with a better browsing
            experience. Find out more about how we use cookies and how you can
            change your preferences by reading our
            <router-link to="/cookies-policy">Cookies Policy</router-link>.
          </p>
          <div class="button agree" @click="acceptCookies()">I agree</div>
          <div class="button decline" @click="declineCookies()">Decline</div>
        </div>
        <div
          v-else-if="lang === 'de'"
          class="d-flex flex-column flex-md-row justify-center align-center"
        >
          <p>
            Diese Website verwendet Cookies und ähnliche Technologien, die uns
            dabei helfen, die Website ordnungsgemäß zu betreiben und Ihnen ein
            besseres Browser-Erlebnis zu bieten. Weitere Informationen über die
            Verwendung von Cookies und darüber, wie Sie Ihre Einstellungen
            ändern können, finden Sie in unserer
            <router-link to="/cookies-policy">Cookie-Richtlinie</router-link>.
          </p>
          <div class="button agree" @click="acceptCookies()">Zustimmen</div>
          <div class="button decline" @click="declineCookies()">Ablehnen</div>
        </div>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import { bootstrap } from "vue-gtag";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Cookie",
  data() {
    return {
      showDialog: false
    };
  },
  methods: {
    ...mapMutations(["setCookieConsent"]),
    loadGTag() {
      bootstrap().then(() => {
        // all done!
      });
    },
    loadCookies() {
      this.loadGTag();
    },
    acceptCookies() {
      this.setCookieConsent(true);
      this.loadCookies();
      this.showDialog = false;
    },
    declineCookies() {
      this.setCookieConsent(false);
      this.showDialog = false;
    }
  },
  created() {
    if (!this.cookieConsent) {
      this.showDialog = true;
    } else {
      this.loadCookies();
    }
  },
  computed: {
    ...mapState(["cookieConsent", "lang"])
  }
};
</script>

<style scoped lang="scss">
.content {
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 210;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
}
.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: normal;
  margin: 10px 10px 5px 10px;
  padding: 10px;
  @media screen and (min-width: 960px) {
    margin: 0 10px;
    width: 200px;
  }
  &:hover {
    cursor: pointer;
  }
}
.agree {
  background-color: var(--red);
}
.decline {
  background-color: gray;
}
</style>
