<template>
  <li>
    <div class="li-flex">
      <div>{{ $t("DETAILS.YOUR_PRICE") }}</div>
      <div v-if="object.price.prices[location].promotion">
        <span class="osram-new">{{
          $t("COMMON.PRICE", {
            price: price,
            currency: object.price.symbol
          })
        }}</span>
        <span class="osram-old">{{
          $t("COMMON.PRICE", {
            price: oldPrice,
            currency: object.price.symbol
          })
        }}</span>
      </div>
      <div v-else>
        {{
          $t("COMMON.PRICE", {
            price: price,
            currency: object.price.symbol
          })
        }}
      </div>
    </div>
    <div class="li-flex">
      <small>{{ $t("DETAILS.FOR_ONE_PIECE") }}</small>
      <small v-if="isVATIncluded">({{ $t("DETAILS.INCL_VAT") }})</small>
    </div>
  </li>
</template>

<script>
import config from "./../../3c.config";

export default {
  name: "Price",
  props: {
    object: Object,
    location: String
  },
  data: () => ({
    config
  }),
  computed: {
    isVATIncluded() {
      const candidate =
        this.object &&
        this.object.price &&
        this.object.price.prices[this.location];
      return candidate.grossprice !== candidate.price;
    },
    price() {
      return this.object.price.prices[this.location].grossprice;
    },
    oldPrice() {
      return this.object.price.baseprice;
    }
  }
};
</script>

<style scoped lang="scss">
.osram {
  &-old {
    text-decoration: line-through;
  }

  &-new {
    margin-right: 10px;
    color: red;
    font-weight: bolder;
  }
}

.li-flex {
  display: flex;
  justify-content: space-between;
  text-align: left;

  div:last-of-type {
    text-align: right;
  }
}
</style>
